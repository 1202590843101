import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

const cardVariants = {
  offscreen: {
    y: -20,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.6,
      duration: 2,
      delay: 0.2,
    },
  },
};

const CustomHeader = ({ isPrimary, headerText }) => {
  const textColorClass = isPrimary ? "text-primary-900" : "text-secondary-300";
  const [scaleValue, setScaleValue] = useState(1);

  // ========================================================================  //
  // 🟩 Effects

  useEffect(() => {
    const handleScroll = () => {
      var a = 1 + window.scrollY * 0.0001;
      setScaleValue(a);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scaleValue]);

  return (
    <motion.div className="card-container" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
      <motion.div className="card" variants={cardVariants}>
        <h2
          className={`text-primary-100 text-5xl mb-12 backdrop:max-md:text-4xl font-nunito font-light leading-tight tracking-tight uppercase text-center relative ${textColorClass}`}
        >
          {headerText}
          <span className="block h-4 border-b-4 border-secondary-500 w-12 border-secondary-400 absolute bottom--5 left-0 right-0 mx-auto"></span>
        </h2>
      </motion.div>
    </motion.div>
  );
};

export default CustomHeader;
