import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import "./App.css";

import Home from "./pages/Home";
import AboutPage from "./pages/AboutPage";
import NotFoundPage from "./pages/NotFoundPage";
import ServicesPage from "./pages/ServicesPage";
import DriversPage from "./pages/DriversPage";
import ContactPage from "./pages/ContactPage";
import TermsPage from "./pages/TermsPage";
import PrivacyPage from "./pages/PrivacyPage";

const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

function App() {
  useEffect(() => {
    const imageUrls = [
      "public/images/about.jpg",
      "public/images/beautiful-mountain-landscape.jpg",
      // "public/images/professional-middle-aged-trucker-cabin-driving-truck-smiling.jpg",
      "public/images/truck-driver-inspecting-truck-long-vehicle-before-driving.jpg",
      // "public/images/truck-driver-occupation-service.jpg",
      "public/images/truck-history.jpg",
      "public/images/truck-mission.jpg",
      "public/images/box_13401875.png",
      "public/images/clock_2838794.png",
      "public/images/logo5.png",
      "public/images/logo6.png",
      "public/images/semi_3061680.png",
      "public/images/star_1828970.png",
      "public/images/logo.png",
      "public/images/logo1.png",
      "public/images/logo2.png",
      "public/images/logo3.png",
      "public/images/logo4.png",
      "public/images/logo7.png",
      "public/images/logo8.png",
      "public/images/logo9.png",
      "public/images/logo10.png",
      "public/images/office-building_2830596.png",
      "public/images/steering-wheel_1581955.png",
      "public/images/time_9905643.png",
      "public/images/trucks-highway-mountain-sunset.jpg",
      "public/images/trucks.jpg",
      "public/images/highroad.jpg",
      "public/images/truck-company.jpg",
      "public/images/driver-cabin.jpg",
    ];

    preloadImages(imageUrls);
  }, []);

  return (
    <Router>
      <AnimatePresence>
        <motion.div initial={{ opacity: 0, x: 0 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: 0 }} transition={{ duration: 0.5 }}>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/drivers" element={<DriversPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </motion.div>
      </AnimatePresence>
    </Router>
  );
}

export default App;
