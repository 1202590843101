import React, { useEffect } from "react";
import { motion } from "framer-motion";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import OurTestimonials from "../components/elements/OurTestimonials";
import CustomHeader from "../components/elements/CustomHeader";

const ServicesPage = () => {
  const cardVariantsScaled = {
    offscreen: {
      y: -20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.2,
      },
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} transition={{ duration: 0.5 }}>
      <NavBar />

      <motion.section
        className="relative max-h-[480px] flex justify-center items-center bg-center bg-cover"
        style={{ backgroundImage: `url(/images/trucks-highway-mountain-sunset.jpg)`, backgroundSize: "cover", height: "100vh" }}
      >
        <div className="absolute inset-0 flex flex-col justify-center items-center z-20">
          <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }} variants={cardVariantsScaled}>
            <div className="flex flex-col justify-center items-center transition-all w-full">
              <h1
                className={`text-center text-primary-900 text-opacity-40 font-bold leading-normal text-9xl mx-6 relative max-[600px]:text-5xl flex flex-col uppercase`}
              >
                Services
              </h1>
            </div>
          </motion.div>
        </div>
      </motion.section>

      <div className="max-w-screen-lg my-24 mx-auto md:px-6 px-8">
        <section className="mb-32 text-center">
          <div className="grid gap-x-6 md:grid-cols-3 lg:gap-x-12">
            <div className="mb-12 md:mb-0">
              <div className="mb-6 inline-block rounded-md bg-primary-800 p-4 text-primary">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
                  />
                </svg>
              </div>
              <h5 className="mb-4 text-lg font-bold text-secondary-400">REEFER</h5>
              <p className="text-primary-900">
                We possess the essential equipment, knowledge, and expertise to transport your refrigerated goods seamlessly across the nation.
              </p>
            </div>

            <div className="mb-12 md:mb-0">
              <div className="mb-6 inline-block rounded-md bg-primary-800 p-4 text-primary">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 0 0-1 1H6a2 2 0 0 0-2 2v15c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-2c0-.6-.4-1-1-1H9Zm1 2h4v2h1a1 1 0 1 1 0 2H9a1 1 0 0 1 0-2h1V4Zm5.7 8.7a1 1 0 0 0-1.4-1.4L11 14.6l-1.3-1.3a1 1 0 0 0-1.4 1.4l2 2c.4.4 1 .4 1.4 0l4-4Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <h5 className="mb-4 text-lg font-bold text-secondary-400">DRY</h5>
              <p className="text-primary-900">From paper products to metal equipment, we ensure the prompt transportation of your dry goods.</p>
            </div>

            <div className="mb-12">
              <div className="mb-6 inline-block rounded-md bg-primary-800 p-4 text-primary">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 11v5m0 0 2-2m-2 2-2-2M3 6v1c0 .6.4 1 1 1h16c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H4a1 1 0 0 0-1 1Zm2 2v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V8H5Z"
                  />
                </svg>
              </div>
              <h5 className="mb-4 text-lg font-bold text-secondary-400">FTL/LTL</h5>
              <p className="text-primary-900">
                We collaborate closely with our customers and partners to meet their unique requirements. Whether it's a full truckload or
                less-than-truckload, we assist in identifying optimal solutions for transporting your freight.
              </p>
            </div>
          </div>

          <div className="grid gap-x-6 md:grid-cols-3 lg:gap-x-12">
            <div className="mb-12 md:mb-0">
              <div className="mb-6 inline-block rounded-md bg-primary-800 p-4 text-primary">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M13.8 4.2a2 2 0 0 0-3.6 0L8.4 8.4l-4.6.3a2 2 0 0 0-1.1 3.5l3.5 3-1 4.4c-.5 1.7 1.4 3 2.9 2.1l3.9-2.3 3.9 2.3c1.5 1 3.4-.4 3-2.1l-1-4.4 3.4-3a2 2 0 0 0-1.1-3.5l-4.6-.3-1.8-4.2Z" />
                </svg>
              </div>
              <h5 className="mb-4 text-lg font-bold text-secondary-400">HIGH-VALUE</h5>
              <p className="text-primary-900">
                We recognize the significance of shipments that require meticulous care and dependability. Our commitment to reliable communication
                and personal responsibility enables us to provide 'white glove' management for such sensitive shipments.
              </p>
            </div>

            <div className="mb-12 md:mb-0">
              <div className="mb-6 inline-block rounded-md bg-primary-800 p-4 text-primary">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
              <h5 className="mb-4 text-lg font-bold text-secondary-400">DEDICATED</h5>
              <p className="text-primary-900">
                Some shipments demand unwavering reliability, and it takes dedicated individuals to ensure the seamless delivery of the product. This
                is where our committed company drivers excel, taking on the challenge with precision.
              </p>
            </div>

            <div className="mb-12">
              <div className="mb-6 inline-block rounded-md bg-primary-800 p-4 text-primary">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20ZM8 9c0-.6.4-1 1-1a1 1 0 0 1 0 2 1 1 0 0 1-1-1Zm6 0c0-.6.4-1 1-1a1 1 0 1 1 0 2 1 1 0 0 1-1-1Zm-5.5 7.2c-1-.8-1.7-2-1.9-3.2h10.8a5.5 5.5 0 0 1-9 3.2Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <h5 className="mb-4 text-lg font-bold text-secondary-400">EXPEDITED</h5>
              <p className="text-primary-900">
                With time-sensitive cargo, our professional and seasoned drivers ensure you meet every deadline. Whether it's a robust team effort or
                a dependable solo mission, you can rely on us to deliver on time, every time.
              </p>
            </div>
          </div>
        </section>

        {/* <div className="flex flex-col justify-center items-center bg-gradient-to-b">
          <CustomHeader isPrimary={true} headerText={"TESTIMONIALS"} />

          <OurTestimonials />
        </div> */}
      </div>

      <Footer />
    </motion.div>
  );
};

export default ServicesPage;
