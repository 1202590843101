import { motion } from "framer-motion";
import React from "react";
import Carousel from "react-material-ui-carousel";

const OurTestimonials = () => {
  const cardVariants = {
    offscreen: {
      y: -10,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.6,
        delay: 0,
      },
    },
  };

  const testimonials = [
    {
      name: "Logistics Specialist",
      role: "",
      text: "Prompt responses and excellent communication. Running a dedicated lane for a year with minimal issues. Their deep industry knowledge and willingness to assist make them a reliable partner!",
      img: "./images/logo1.png",
    },
    {
      name: "Logistics Specialist",
      role: "",
      text: "Working with Agimun for 3 years has been excellent. They offer great communication, swift resolution of shipment issues, and pleasant drivers. As one of our main carriers, we trust them with our shipments.",
      img: "./images/logo2.png",
    },
    {
      name: "Logistics Specialist",
      role: "",
      text: "Agimun gets the job done! Working with an honest, responsive, and effective team is a pleasure. They go above and beyond in servicing our customer's freight. Appreciate your business, you're the best! 👍",
      img: "./images/logo3.png",
    },
  ];

  function Item(props) {
    return (
      <div
        id="carouselExampleCaptions"
        className="relative flex flex-col text-center overflow-hidden justify-center"
        style={{ minHeight: "480px" }}
        data-te-carousel-init
        data-te-carousel-slide
      >
        <div className="flex flex-wrap justify-center">
          <div className="w-full shrink-0 grow-0 basis-auto px-3 lg:w-8/12 max-[600px]:px-12">
            <img className="mx-auto mb-2 w-[100px]" src={props.item.img} alt="logo" />
            <p className="mb-6 text-3xl text-primary-900 opacity-70 max-[600px]:text-lg">"{props.item.text}"</p>
            <h5 className="mb-2 text-primary-900 text-sm font-light">{props.item.name}</h5>
          </div>
        </div>
      </div>
    );
  }

  function NavButtonRight() {
    return (
      <span className="inline-block h-8 w-8">
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="text-neutral-300">
          <path
            fill="currentColor"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </span>
    );
  }

  function NavButtonLeft() {
    return (
      <span className="inline-block h-8 w-8">
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="text-neutral-300">
          <path
            fill="currentColor"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          />
        </svg>
      </span>
    );
  }

  return (
    <div className="container md:px-6 max-w-[980px]">
      <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
        <div className="splash" />
        <motion.div className="card" variants={cardVariants}>
          <Carousel
            NavButton={({ onClick, className, style, next, prev }) => {
              // Other logic

              return (
                <div onClick={onClick} className="relative top-1/2 cursor-pointer">
                  {next && <NavButtonRight />}
                  {prev && <NavButtonLeft />}
                </div>
              );
            }}
            indicatorIconButtonProps={{
              style: {
                color: "#435D89", // 3
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: "#d7be9f", // 2
              },
            }}
            navButtonsAlwaysVisible={true}
            indicators={false}
            animation={"slide"}
            interval={8000}
            duration={400}
          >
            {testimonials.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </Carousel>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default OurTestimonials;
