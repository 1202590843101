import React, { useEffect } from "react";
import { motion } from "framer-motion";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const NotFoundPage = () => {
  const cardVariantsScaled = {
    offscreen: {
      y: -20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.2,
      },
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} transition={{ duration: 0.5 }}>
      <NavBar />

      <motion.section
        className="relative max-h-[480px] flex justify-center items-center bg-center bg-cover"
        style={{ backgroundImage: `url(/images/beautiful-mountain-landscape.jpg)`, backgroundSize: "cover", height: "100vh" }}
      >
        <div className="absolute inset-0 flex flex-col justify-center items-center z-20">
          <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }} variants={cardVariantsScaled}>
            <div className="flex flex-col justify-center items-center transition-all w-full">
              <h1
                className={`text-center text-primary-100 text-opacity-70 font-bold leading-normal text-3xl mx-6 relative max-[600px]:text-5xl flex flex-col uppercase`}
              >
                PAGE NOT FOUND
              </h1>
            </div>
          </motion.div>
        </div>
      </motion.section>

      <div className="max-w-screen-lg my-24 mx-auto md:px-6">
        <section className="mb-32">
          <div className="text-xs text-center">
            <strong>The 404 page appears when a requested web page is not found, signaling that the content is unavailable.</strong>
          </div>
        </section>
      </div>

      <Footer />
    </motion.div>
  );
};

export default NotFoundPage;
