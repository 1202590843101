import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";

const NavBar = () => {
  const [isToggled, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!isToggled);
  };

  const navContainer = {
    visible: {
      opacity: 1,
      transition: {
        x: { velocity: 100 },
        duration: 0.3,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        x: { velocity: 100 },
        duration: 0.3,
      },
    },
  };

  //
  // Navigation Dom, Active class
  //
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    // Extract the pathname from the current URL
    const currentPath = location.pathname;

    // Set the active link based on the current pathname
    setActiveLink(currentPath);
  }, [location.pathname]);

  return (
    <div className="z-50 relative">
      <nav
        className={`bg-black bg-opacity-75 z-50 rounded-b-lg min-h-[60px] shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[30px] fixed top-0 flex w-full flex-wrap items-center justify-between transition-all text-neutral-500 hover:text-neutral-700 focus:text-neutral-700 lg:flex-wrap lg:justify-start`}
        data-te-navbar-ref
      >
        <div className="max-w-screen-lg m-auto flex w-full flex-wrap items-center justify-between px-3">
          <button
            className="block border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 lg:hidden"
            type="button"
            onClick={handleToggle}
            data-te-collapse-init
            data-te-target="#navbarSupportedContent8"
            aria-controls="navbarSupportedContent8"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="[&>svg]:w-7">
              {isToggled ? (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-7 w-7">
                  <path
                    fillRule="evenodd"
                    d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </span>
          </button>

          {!isHomePage && (
            <div className="font-nunito text-xl max-[600px]:hidden">
              <motion.button whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 400, damping: 10 }} className="cursor-pointer">
                <a href="/" className="flex">
                  <motion.img
                    src="images/logo.png"
                    alt="Agimun"
                    style={{ width: "60px" }}
                    className="mt-2"
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                  />
                </a>
              </motion.button>
            </div>
          )}

          <div
            className="!visible mt-2 hidden flex-grow basis-[100%] items-center justify-center lg:mt-0 lg:!flex lg:basis-auto"
            id="navbarSupportedContent8"
            data-te-collapse-item
          >
            <ul className="list-style-none flex flex-col pl-0 lg:mt-1 lg:flex-row" data-te-navbar-nav-ref>
              <li className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-1" data-te-nav-item-ref>
                <a
                  className={`relative after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 after:bg-primary-300 after:transition-transform after:duration-300 after:ease-[cubic-bezier(0.65_0.05_0.36_1)] hover:after:origin-bottom-left hover:after:scale-x-100 relative after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 after:bg-primary-300 after:transition-transform after:duration-300 after:ease-[cubic-bezier(0.65_0.05_0.36_1)] hover:after:origin-bottom-left hover:after:scale-x-100 p-0 text-primary-300 transition duration-200 hover:text-secondary-400 hover:ease-in-out focus:text-primary-100 motion-reduce:transition-none text-lg mx-2 font-nunito font-bold ${
                    activeLink === "/" ? "text-secondary-400" : ""
                  }`}
                  href="/"
                  data-te-nav-link-ref
                >
                  Home
                </a>
              </li>
              <li className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-1" data-te-nav-item-ref>
                <a
                  className={`relative after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 after:bg-primary-300 after:transition-transform after:duration-300 after:ease-[cubic-bezier(0.65_0.05_0.36_1)] hover:after:origin-bottom-left hover:after:scale-x-100 p-0 text-primary-300 transition duration-200 hover:text-secondary-400 hover:ease-in-out focus:text-primary-100 motion-reduce:transition-none text-lg mx-2 font-nunito font-bold ${
                    activeLink === "/about" ? "text-secondary-400" : ""
                  }`}
                  href="/about"
                  data-te-nav-link-ref
                >
                  About
                </a>
              </li>
              <li className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-1" data-te-nav-item-ref>
                <a
                  className={`relative after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 after:bg-primary-300 after:transition-transform after:duration-300 after:ease-[cubic-bezier(0.65_0.05_0.36_1)] hover:after:origin-bottom-left hover:after:scale-x-100 p-0 text-primary-300 transition duration-200 hover:text-secondary-400 hover:ease-in-out focus:text-primary-100 motion-reduce:transition-none text-lg mx-2 font-nunito font-bold ${
                    activeLink === "/services" ? "text-secondary-400" : ""
                  }`}
                  href="/services"
                  data-te-nav-link-ref
                >
                  Services
                </a>
              </li>
              {/* <li className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-1" data-te-nav-item-ref>
                <a
                  className={`relative after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 after:bg-primary-300 after:transition-transform after:duration-300 after:ease-[cubic-bezier(0.65_0.05_0.36_1)] hover:after:origin-bottom-left hover:after:scale-x-100 p-0 text-primary-300 transition duration-200 hover:text-secondary-400 hover:ease-in-out focus:text-primary-100 motion-reduce:transition-none text-lg mx-2 font-nunito font-bold ${
                    activeLink === "/drivers" ? "text-secondary-400" : ""
                  }`}
                  href="/drivers"
                  data-te-nav-link-ref
                >
                  Drivers
                </a>
              </li> */}
              <li className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-1" data-te-nav-item-ref>
                <a
                  className={`relative after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 after:bg-primary-300 after:transition-transform after:duration-300 after:ease-[cubic-bezier(0.65_0.05_0.36_1)] hover:after:origin-bottom-left hover:after:scale-x-100 p-0 text-primary-300 transition duration-200 hover:text-secondary-400 hover:ease-in-out focus:text-primary-100 motion-reduce:transition-none text-lg mx-2 font-nunito font-bold ${
                    activeLink === "/contact" ? "text-secondary-400" : ""
                  }`}
                  href="/contact"
                  data-te-nav-link-ref
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <AnimatePresence>
        {isToggled && (
          <motion.div
            className="navbar gradientMasterSet fixed z-10 w-full h-dvh flex items-center justify-center"
            initial="hidden"
            animate={isToggled ? "visible" : "hidden"}
            exit="hidden"
            variants={navContainer}
          >
            <ul className="list-style-none flex flex-col pl-0 lg:mt-1 lg:flex-row" data-te-navbar-nav-ref>
              <li className="my-4 text-center" data-te-nav-item-ref>
                <a
                  className={`p-0 text-primary-300 transition duration-200 hover:text-secondary-400 hover:ease-in-out focus:text-primary-100 motion-reduce:transition-none text-lg font-nunito font-bold ${
                    activeLink === "/" ? "text-secondary-400" : ""
                  }`}
                  href="/"
                  data-te-nav-link-ref
                >
                  Home
                </a>
              </li>
              <li className="my-4 text-center" data-te-nav-item-ref>
                <a
                  className={`p-0 text-primary-300 transition duration-200 hover:text-secondary-400 hover:ease-in-out focus:text-primary-100 motion-reduce:transition-none text-lg font-nunito font-bold ${
                    activeLink === "/about" ? "text-secondary-400" : ""
                  }`}
                  href="/about"
                  data-te-nav-link-ref
                >
                  About
                </a>
              </li>
              <li className="my-4 text-center" data-te-nav-item-ref>
                <a
                  className={`p-0 text-primary-300 transition duration-200 hover:text-secondary-400 hover:ease-in-out focus:text-primary-100 motion-reduce:transition-none text-lg font-nunito font-bold ${
                    activeLink === "/services" ? "text-secondary-400" : ""
                  }`}
                  href="/services"
                  data-te-nav-link-ref
                >
                  Services
                </a>
              </li>
              {/* <li className="my-4 text-center" data-te-nav-item-ref>
                <a
                  className={`p-0 text-primary-300 transition duration-200 hover:text-secondary-400 hover:ease-in-out focus:text-primary-100 motion-reduce:transition-none text-lg font-nunito font-bold ${
                    activeLink === "/drivers" ? "text-secondary-400" : ""
                  }`}
                  href="/drivers"
                  data-te-nav-link-ref
                >
                  Drivers
                </a>
              </li> */}
              <li className="my-4 text-center" data-te-nav-item-ref>
                <a
                  className={`p-0 text-primary-300 transition duration-200 hover:text-secondary-400 hover:ease-in-out focus:text-primary-100 motion-reduce:transition-none text-lg font-nunito font-bold ${
                    activeLink === "/contact" ? "text-secondary-400" : ""
                  }`}
                  href="/contact"
                  data-te-nav-link-ref
                >
                  Contact
                </a>
              </li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NavBar;
