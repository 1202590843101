import React, { useEffect } from "react";
import { motion } from "framer-motion";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import CustomHeader from "../components/elements/CustomHeader";

const AboutPage = () => {
  const cardVariantsScaled = {
    offscreen: {
      y: -20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.2,
      },
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} transition={{ duration: 0.5 }}>
      <NavBar />

      <motion.section
        className="relative max-h-[480px] flex justify-center items-center bg-center bg-cover"
        style={{
          backgroundImage: `url(/images/truck-driver-inspecting-truck-long-vehicle-before-driving.jpg)`,
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <div className="absolute inset-0 flex flex-col justify-center items-center z-20">
          <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }} variants={cardVariantsScaled}>
            <div className="flex flex-col justify-center items-center transition-all w-full">
              <h1
                className={`text-center text-primary-100 text-opacity-70 font-bold leading-normal text-9xl mx-6 relative max-[600px]:text-5xl flex flex-col uppercase`}
              >
                About
              </h1>
            </div>
          </motion.div>
        </div>
      </motion.section>

      <div className="max-w-screen-lg my-24 mx-auto md:px-6">
        <section className="mb-32">
          <div className="block rounded-lg bg-white">
            <div className="px-6 py-12 md:px-12">
              <CustomHeader isPrimary={true} headerText={"ABOUT"} />

              <img src="images/about.jpg" alt="about" className="rounded-lg" />
            </div>

            <div className="flex flex-wrap items-center">
              <div className="w-full shrink-0 grow-0 basis-auto lg:w-6/12 xl:w-8/12">
                <div className="px-6 py-12 md:px-12">
                  <h2 className="mb-6 pb-2 text-4xl font-bold">BACKGROUND</h2>
                  <p className="mb-6 pb-2 text-primary-900">
                    Agimun was founded with a clear mission: to establish a company that recognizes and supports the essential driving force of our
                    economy – our drivers. Arslan, our company's president, brings a wealth of experience to the table. He has transitioned from a
                    company driver to an Owner-Operator, and eventually realized his dream of establishing his own trucking company. This journey has
                    provided him with invaluable perspectives, insights, and experiences, making the demanding road worthwhile in achieving his goal
                    of opening his own company.
                  </p>

                  <p className="mb-6 pb-2 text-primary-900">
                    Mr. Arslan Ganzorig, an esteemed individual with extensive experience in the transportation industry.
                  </p>

                  <p className="mb-6 pb-2 text-primary-900">
                    Mr. Ganzorig boasts a remarkable 12-year tenure within the trucking sector, demonstrating profound expertise and a deep
                    understanding of the intricacies involved in logistics and transportation management. His dedication and commitment to excellence
                    have been evident throughout his career, earning him respect and recognition within the industry.
                  </p>

                  <p className="mb-6 pb-2 text-primary-900">
                    In 2014, Mr. Ganzorig took a bold step forward by establishing Golden Wheel Transport INC, a testament to his entrepreneurial
                    spirit and vision for growth. Under his leadership, Golden Wheel Transport INC has flourished, delivering exceptional service and
                    establishing itself as a prominent player in the transportation sector.
                  </p>

                  <p className="mb-6 pb-2 text-primary-900">
                    Furthermore, Mr. Ganzorig's entrepreneurial drive led him to establish Agimun Inc in 2023, where he is actively engaged in raising
                    funds to support the company's growth and expansion initiatives. His strategic foresight and innovative approach position Agimun
                    Inc for success in an ever-evolving market landscape.
                  </p>

                  <p className="mb-6 pb-2 text-primary-900">
                    With a proven track record of leadership, integrity, and success, Mr. Ganzorig is poised to continue making significant
                    contributions to the transportation industry.
                  </p>
                </div>
              </div>
              <div className="max-w-screen-xl:visible hidden w-full shrink-0 grow-0 basis-auto lg:flex lg:w-6/12 xl:w-4/12">
                <img src="images/truck-history.jpg" alt="Trendy Pants and Shoes" className="w-full rounded-lg" />
              </div>
            </div>

            <div className="flex flex-wrap items-center my-12">
              <div className="max-w-screen-xl:visible hidden w-full shrink-0 grow-0 basis-auto lg:flex lg:w-6/12 xl:w-4/12">
                <img src="images/truck-mission.jpg" alt="Trendy Pants and Shoes" className="w-full rounded-lg" />
              </div>
              <div className="w-full shrink-0 grow-0 basis-auto lg:w-6/12 xl:w-8/12">
                <div className="px-6 py-12 md:px-12">
                  <h2 className="mb-6 pb-2 text-4xl font-bold">MISSION</h2>
                  <p className="mb-6 pb-2 text-primary-900">
                    Agimun attributes its current standing to our dedicated, professional, and responsible drivers, whom we deeply value. We take
                    pride in supporting these men and women, who invest considerable effort, time, and patience into this industry. Committed to
                    operating with care, honesty, and transparency, we strive to foster and maintain mutually beneficial relationships with our
                    partners and customers. Through this approach, we aim to excel in the services we provide while prioritizing the well-being of our
                    drivers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div ref={refStats}>
          <motion.div key="OurStats" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Parallax translateY={-[40, 40]}>
              <OurStats isVisible={statsInView} />
            </Parallax>
          </motion.div>
        </div> */}
      </div>

      <Footer />
    </motion.div>
  );
};

export default AboutPage;
