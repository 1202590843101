import React from "react";
import { Parallax } from "react-scroll-parallax";

const OurQiality = () => {
  return (
    <div className="container max-w-screen-lg my-24 mx-auto md:px-6 text-center">
      <p>
        At Agimun, as we pave the way for our operations, our focus is on laying robust foundations. We eagerly anticipate collaborating with esteemed
        business partners in brokering and serving potential future customers – prominent consumer brands that will propel our economy forward. Even
        in our pre-operational phase, we take pride in positioning ourselves as a prospective trusted partner and a reliable service provider in the
        industry. Our unwavering commitment to excellence sets the stage for the success and growth we aim to achieve together in the near future.
      </p>
      <Parallax translateY={[-10, 10]}>
        <div className="gridLogo max-w-[520px] m-auto">
          <img src="images/logo1.png" width={120} alt="logo" />
          <img src="images/logo2.png" width={120} alt="logo" />
          <img src="images/logo3.png" width={120} alt="logo" />
          <img src="images/logo4.png" width={120} alt="logo" />
          <img src="images/logo5.png" width={120} alt="logo" />
          <img src="images/logo6.png" width={120} alt="logo" />
          <img src="images/logo7.png" width={120} alt="logo" />
          <img src="images/logo8.png" width={120} alt="logo" />
          <img src="images/logo9.png" width={120} alt="logo" />
          <img src="images/logo10.png" width={120} alt="logo" />
        </div>
      </Parallax>
    </div>
  );
};

export default OurQiality;
