import React, { useState, useRef, useEffect } from "react";
import { ScrollPage } from "react-scroll-motion";
import { motion, useAnimation } from "framer-motion";

import { Parallax } from "react-scroll-parallax";
import NavBar from "../components/NavBar";
import OurQuality from "../components/OurQuality";

import CustomHeader from "../components/elements/CustomHeader";

import "../App.css";
import Footer from "../components/Footer";
// Nope... Unless it isn't supported...
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function Home() {
  const videoRef = useRef(null);

  useEffect(() => {
    if (!CSS.supports("animation-timeline: scroll()") && matchMedia("(prefers-reduced-motion: no-preference)")) {
      gsap.registerPlugin(ScrollTrigger);

      const scrub = 0.5;
      const trigger = "main";

      gsap.set("p > span", {
        "--progress": 0,
        backgroundPositionX: "calc(-200vmax + (var(--progress) * 200vmax)), calc(-200vmax + (var(--progress) * 200vmax)), 0",
        color: "transparent",
      });

      gsap.to("p > span", {
        "--progress": 1,
        scrollTrigger: {
          trigger,
          scrub,
          start: "top top",
          end: "top top-=75%",
        },
      });

      gsap.to("p > span", {
        color: "white",
        scrollTrigger: {
          trigger,
          scrub,
          start: "top top-=75%",
          end: "bottom bottom",
        },
      });
    }
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      // Set the default playback rate to 0.5 (half speed)
      videoRef.current.playbackRate = 0.7;
    }
  }, []);

  const handleVideoEnded = () => {
    // Pause the video when it reaches the end
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: 1,
      y: 0,
      transition: { duration: 1.2, ease: "easeOut" },
    });
  }, [controls]);

  const videoPaths = ["images/truck.mp4", "images/highway.mp4", "images/production.mp4"];

  const cardVariants = {
    offscreen: {
      y: -20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 2,
        delay: 0.4,
      },
    },
  };

  return (
    <div className="App scroll-smooth">
      <NavBar />

      {/*
        ||
        || 🟩 Big Picture
        ||
        */}
      <ScrollPage className="gradientMasterSet w-full h-full flex flex-col justify-center items-center min-h-svh" style={{ height: "auto" }}>
        <div className="p-12">
          <VideoPlayer videos={videoPaths} />
          <Parallax translateY={[-80, 80]}>
            <motion.div initial={{ opacity: 0, y: -20 }} animate={controls} exit="never" className="z-50 relative">
              <img src="images/logo.png" alt="Logo" width={320} className="mx-auto" />
              <h1 className=" text-2xl text-primary-100 font-nunito">Trusted Hauls, Timely Deliveries.</h1>
            </motion.div>
          </Parallax>
        </div>
      </ScrollPage>

      {/*
        ||
        || 🟩 MISSION
        ||
        */}
      <ScrollPage className="bg-slate-300 w-full h-full flex flex-col justify-center items-center min-h-svh" style={{ height: "auto" }}>
        <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", overflow: "hidden" }}>
          <video
            ref={videoRef}
            autoPlay
            muted
            playsInline
            onEnded={handleVideoEnded}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          >
            <source src="images/clouds.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div class="max-w-screen-2xl my-24 mx-auto md:px-6">
          <section class="mb-32">
            <div
              className="relative max-h-[480px] mx-4 flex justify-center items-center bg-center bg-cover rounded-3xl"
              style={{ backgroundImage: `url(/images/trucks-highway-mountain-sunset.jpg)`, backgroundSize: "cover", height: "100vh" }}
            ></div>
            <div class="container mx-auto px-6 md:px-12 xl:px-32">
              <Parallax translateY={[-20, 20]}>
                <div class="text-center">
                  <div class="mt-[-70px] block rounded-lg bg-[hsla(0,0%,100%,0.55)] bg-[hsla(0,0%,100%,0.7)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[30px] md:px-12">
                    <motion.div className="card-container" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
                      <motion.div className="card" variants={cardVariants}>
                        <CustomHeader isPrimary={true} headerText={"MISSION"} />
                        <p className="mb-12">
                          At Agimun Inc, as we embark on this exciting venture, our core values center around secure transportation, dependable
                          communication, and transparency. However, we go beyond the ordinary – we are devoted to nurturing strong relationships.
                          Leading by example, our primary focus is on the well-being and safety of our drivers, ensuring a foundation of trust and
                          excellence in all that we do.
                        </p>
                      </motion.div>
                    </motion.div>
                  </div>
                </div>
              </Parallax>
            </div>
          </section>
        </div>

        <div className="p-12">
          <div class="max-w-screen-lg mx-auto md:px-6">
            <div class="flex flex-wrap">
              <div class="mb-12 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-5/12">
                <div class="flex lg:py-12">
                  <motion.div className="card-container" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
                    <motion.div className="card" variants={cardVariants}>
                      <img src="images/truck-company.jpg" alt="ABOUT" class="w-full rounded-lg shadow-lg dark:shadow-black/20 lg:ml-[50px] z-[10]" />
                    </motion.div>
                  </motion.div>
                </div>
              </div>
              <div class="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
                <div class="flex h-full items-center rounded-lg bg-primary p-6 text-center text-white lg:pl-12 lg:text-left">
                  <motion.div className="card-container" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
                    <motion.div className="card" variants={cardVariants}>
                      <div class="lg:pl-12">
                        <h2 class="mb-6 text-5xl text-primary-800 font-nunito font-thin text-secondary-900">ABOUT</h2>
                        <p class="mb-6 pb-2 lg:pb-0 text-primary-900">
                          At Agimun, our integrity stands unwavering, undeterred by the constraints of our size. The magnitude of our organization
                          neither diminishes nor sways us from our steadfast mission. We are committed to upholding the highest standards, ensuring
                          that our principles remain resilient, regardless of our scale.
                        </p>
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>

          <div class="max-w-screen-lg mx-auto md:px-6">
            <div class="flex flex-wrap">
              <div class="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
                <div class="flex h-full items-center rounded-lg bg-primary p-6 text-center text-white lg:pl-12 lg:text-left">
                  <motion.div className="card-container" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
                    <motion.div className="card" variants={cardVariants}>
                      <div class="lg:pr-12">
                        <h2 class="mb-6 text-5xl text-primary-800 font-nunito font-thin text-secondary-900">SERVICES</h2>
                        <p class="mb-6 pb-2 lg:pb-0 text-primary-900">
                          Specializing in refrigerated freight, Agimun offers a comprehensive range of transportation solutions tailored to your
                          needs. Our services include handling dry shipments, full-truck transport, less-than truckload options, expedited deliveries,
                          white glove services, and dedicated lanes. We are dedicated to providing versatile and reliable solutions for all your
                          logistical requirements.
                        </p>
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
              <div class="mb-12 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-5/12">
                <div class="flex lg:py-12">
                  <motion.div className="card-container" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
                    <motion.div className="card" variants={cardVariants}>
                      <img src="images/highroad.jpg" alt="SERVICES" class="w-full rounded-lg shadow-lg dark:shadow-black/20 lg:mr-[50px] z-[10]" />
                    </motion.div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>

          <div class="max-w-screen-lg mx-auto md:px-6">
            <div class="flex flex-wrap">
              <div class="mb-12 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-5/12">
                <div class="flex lg:py-12">
                  <motion.div className="card-container" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
                    <motion.div className="card" variants={cardVariants}>
                      <img src="images/driver-cabin.jpg" alt="DRIVERS" class="w-full rounded-lg shadow-lg dark:shadow-black/20 lg:ml-[50px] z-[10]" />
                    </motion.div>
                  </motion.div>
                </div>
              </div>
              <div class="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
                <div class="flex h-full items-center rounded-lg bg-primary p-6 text-center text-white lg:pl-12 lg:text-left">
                  <motion.div className="card-container" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
                    <motion.div className="card" variants={cardVariants}>
                      <div class="lg:pl-12">
                        <h2 class="mb-6 text-5xl text-primary-800 font-nunito font-thin text-secondary-900">DRIVERS</h2>
                        <p class="mb-6 pb-2 lg:pb-0 text-primary-900">
                          At Agimun, we take pride in standing shoulder to shoulder with the dedicated men and women who drive our success. We are
                          committed to persistently supporting them, investing significant effort, time, and patience in the transportation industry.
                          Our unwavering dedication ensures that our drivers receive the respect and support they deserve as integral members of the
                          Agimun team.
                        </p>
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollPage>

      {/*
        ||
        || 🟩 TESTIMONIALS
        ||
        */}
      {/* <div className="bg-slate-200 w-full h-full relative">
        <div className="p-12">
          <div className="max-w-screen-lg mx-auto m-auto flex flex-col items-center justify-center">
            <CustomHeader isPrimary={true} headerText={"TESTIMONIALS"} />

            <OurTestimonials />
          </div>
        </div>
      </div> */}

      {/*
        ||
        || 🟩 PARTNERS
        ||
        */}
      <ScrollPage className="bg-slate-100 w-full h-full flex flex-col justify-center items-center min-h-svh" style={{ height: "auto" }}>
        <div className="p-12">
          {/* Title */}
          <CustomHeader isPrimary={true} headerText={"PARTNERS"} />

          <OurQuality />
        </div>
      </ScrollPage>

      {/*
        ||
        || 🟩 FOOTER
        ||
        */}
      <Footer />
    </div>
  );
}

const VideoPlayer = ({ videos, onVideoEnd }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef(null);

  const handleVideoEnded = () => {
    if (currentVideoIndex < videos.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1);
    } else {
      // If the last video ends, start over by resetting the index
      setCurrentVideoIndex(0);
    }
  };

  useEffect(() => {
    // Load the new video when the currentVideoIndex changes
    videoRef.current.load();
  }, [currentVideoIndex]);

  return (
    <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", overflow: "hidden" }}>
      <div className="absolute w-full h-full bg-black opacity-45"></div>
      <video
        ref={videoRef}
        autoPlay
        muted
        playsInline
        onEnded={handleVideoEnded}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      >
        <source src={videos[currentVideoIndex]} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Home;
