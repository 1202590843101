import React from "react";
import { ScrollPage } from "react-scroll-motion";

const Footer = () => {
  return (
    <ScrollPage className="bg-black flex flex-col justify-center items-center min-h-svh" style={{ height: "auto", minHeight: "auto" }}>
      <div className="max-w-screen-xl my-6 mx-auto md:px-6 text-center">
        <img src="images/logo.png" alt="Logo" width={120} className="mx-auto" />
        <p className="w-2/3 pt-10 mx-auto leading-relaxed max-[640px]:leading-normal text-primary-300 text-center">
          <a href="tel:+2244257318" className="transition-all duration-300 hover:text-secondary-100 cursor-pointer max-[640px]:text-sm">
            ‍224-425-7318
          </a>
        </p>
        <p className="max-w-screen-sm mx-auto leading-relaxed max-[640px]:leading-normal text-center">
          <a
            href="https://www.google.com/maps/place/1365+N+Rand+Rd,+Palatine,+IL+60074,+USA/@42.1365855,-88.0228945,16z/data=!3m1!4b1!4m6!3m5!1s0x880fa4a96a41b375:0x72984a34243370e6!8m2!3d42.1370918!4d-88.016129!16s%2Fg%2F11b8v42hjj?entry=ttu"
            target="_blank"
            rel="noreferrer"
            className="transition-all duration-300 text-primary-300 hover:text-secondary-100 cursor-pointer max-[640px]:text-sm leading-1"
          >
            1365 N RAND RD, PALATINE, IL 60074
          </a>
        </p>

        <div className="flex flex-row items-center justify-center mt-10">
          <a href="/terms" rel="noreferrer" className="text-primary-100 text-xs px-6">
            Terms & Conditions
          </a>
          <a href="/privacy" rel="noreferrer" className="text-primary-100 text-xs px-6">
            Privacy Policy
          </a>
        </div>
        <div className="flex flex-row items-center justify-center mt-2">
          <span className="text-[11px] text-primary-600">© Agimun Inc, All rights reserved 2024</span>
        </div>
      </div>
    </ScrollPage>
  );
};

export default Footer;
